
export default Alpine => {

    Alpine.data('MI_Site_Component_Atom_Button', () => ({
        foo() {
            console.log("foo")
        }
    }))

}
